import React from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";

import TextField from '@mui/material/TextField';
import { getLanguage } from 'react-multi-lang';

const Form = (props) => (

    //onLoad={resize(this)}
    <iframe src={(getLanguage() === 'ru') ? 'https://b24-d7co8x.bitrix24.site/crm_form_opvaj' : 'https://b24-d7co8x.bitrix24.site/crm_form_l3sok/'} style={{
    width: '100%',
    height: '550px',
    border: 'none'
}}/>

);

export default Form