import React from 'react';

import '../components/Projects.css'

import img1 from '../img/icon-circle-users.png';
import img2 from '../img/icon-circle-price.png';
import img3 from '../img/icon-circle-boxes.png';
import img4 from '../img/icon-circle-check.png';
import img11 from '../img/img-prod.png';
import img12 from '../img/img-sales.png';
import img13 from '../img/img-install.png';


import { useTranslation, setLanguage } from 'react-multi-lang';

const Projects = (props) => {
    const t = useTranslation()

    return (
    <div>
        <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-12 horizontal-text-with-image">
                <img src={img1}/>
                <span>{t('home.projects.projects1')}</span>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 horizontal-text-with-image">
                <img src={img2}/>
                <span>{t('home.projects.projects2')}</span>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 horizontal-text-with-image">
                <img src={img3}/>
                <span>{t('home.projects.projects3')}</span>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 horizontal-text-with-image">
                <img src={img4}/>
                <span>{t('home.projects.projects4')}</span>
            </div>
        </div>
        <div className="row mt-50">
            <div className="col-md-4 col-xs-12 vertical-text-with-image mb-50">
                <img src={img11}/>
                <h6>{t('home.projects.projects5')}</h6>
                <span>{t('home.projects.projects6')}</span>
            </div>
            <div className="col-md-4 col-xs-12 vertical-text-with-image mb-50">
                <img src={img12}/>
                <h6>{t('home.projects.projects7')}</h6>
                <span>{t('home.projects.projects8')}</span>
            </div>
            <div className="col-md-4 col-xs-12 vertical-text-with-image mb-50">
                <img src={img13}/>
                <h6>{t('home.projects.projects9')}</h6>
                <span>{t('home.projects.projects10')}</span>
            </div>
        </div>
    </div>

    )
};

export default Projects