import React from 'react'
import './Footer.css';
import {Link} from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Support from './Support';


import logo from '../img/logo_white.png';
import iconPhoneOutWhite from '../img/icon-phone-out-white.svg';
import iconClockWhite from '../img/icon-clock-white.svg';
import iconWhatsapp from '../img/icon-whatsapp-colored.svg';
import iconViber from '../img/icon-viber-colored.svg';
import iconSkype from '../img/icon-skype-colored.svg';
import iconEnvelopeWhite from '../img/icon-envelope-white.svg';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Footer = () => {
    const t = useTranslation();
    
    return (
        <footer className="footer">
        <div className="container container--footer">
            <div className="footer__main">
                <Link to="/" className="footer__logo logo-text">
                    <img width="auto" src={logo} alt="NSCAR" />
                </Link>
                <div className="footer__main-text">
                    <aside className="footer__nav">
                        <ul className="footer__nav-list row">
                            <li className="footer__nav-item col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h4>{t('home.footer.КОНТАКТЫ')}</h4>
                                <a href="tel:87172760582" className="header__btn-link text-white">
                                    <img src={iconPhoneOutWhite} alt="Позвонить"/>
                                    8(717) 276-05-82
                                </a>
                                <a href="mailto:info@nscar.kz" className="header__btn-link text-white">
                                    <img src={iconEnvelopeWhite} alt="Написать"/>
                                    info@nscar.kz
                                </a>
                                <span className="header__btn-link text-white">
                                    <img src={iconClockWhite} alt="Время работы" className="header__dashboard-pic"/>
                                    10:00-20:00 ({t('home.header.ПН-ПТ')})<br/>
                                    12:00-17:00 ({t('home.header.СБ-ВС')})
                                </span>
                            </li>
                            <li className="footer__nav-item col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h4>{t('home.footer.МЕНЮ')}</h4>
                                <Link to="/guarantee">{t('home.footer.Гарантия')}</Link>
                                <Link to="/delivery">{t('home.footer.Доставка и оплата')}</Link>
                                <Link to="/opt">{t('home.footer.Купить оптом')}</Link>
                                <Link to="/support#files">{t('home.footer.Инструкции')}</Link>
                                <Link to="/support">{t('home.footer.Техподдержка')}</Link>
                            </li>
                            <li className="footer__nav-item col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <h4>{t('home.footer.РЕКВИЗИТЫ')}</h4>
                                <p>
                                    ТОО "Астра Лайт"<br/>
                                    Казахстан, город Астана, район Нұра, улица Е 900, дом 2, почтовый индекс 010000<br/>
                                    БИН 230340024440<br/><br/>
                                    {t('home.footer.Официальный представитель:')}<br/>
                                    ООО "КЛЕВЕР"<br/>
                                    105082, г. Москва, Рубцовская набережная, д. 3 стр. 1, эт. 1 пом. I ком. 37<br/>
                                    ОРГН 5177746325983, ИНН 9709019150, КПП 770101001
                                </p>
                                <Link to="/politika-konfidentsialnosti" className="footer__nav-link">{t('home.footer.ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ')}</Link>
                            </li>
                        </ul>
                    </aside>
                </div>
            </div>
            <div className="footer__text">
                <p className="m-0 text-size-small">{t('home.footer.footer__text1')}</p>
                <p className="m-0 text-size-small">* {t('home.footer.footer__text2')}</p>
                <p className="m-0 text-size-small">* {t('home.footer.footer__text3')}</p>
                <p className="m-0 text-size-small">* {t('home.footer.footer__text4')}</p>
                <p className="m-0 text-size-small">* {t('home.footer.footer__text5')}</p>
                <p className="m-0 text-size-small">* {t('home.footer.footer__text6')}</p>
                <br></br>
                <p className="m-0 text-gray">© nscar.kz, 2006-2024</p>
            </div>
        </div>
    </footer>
    )
};

export default Footer