import React from 'react';

import './Registrator.css';

import Addon from '../components/Addon';

import reg from '../img/reg.png';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Registrator = (props) => {
    const t = useTranslation()

    return (
    <div className="row">
        <div className="col-md-12 col-lg-5 registrator-img">
            <img src={reg} />
        </div>
        <div className="col-md-12 col-lg-7 registrator-content">
            <div className="addonBlock">

                <ul className="dotted-list not-dotted-onmobile direction-col">
                    <li><div>Разрешение</div><div></div><div>AHD 720P(1280*720)/960H/D1/CIF</div></li>
                    <li><div>720P</div><div></div><div>24Гб / день / 1 канал</div></li>
                    <li><div>960H</div><div></div><div>12-20Гб / день / 1 канал</div></li>
                    <li><div>Запись звука</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop1')}</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop3')}</div><div></div><div>SD до 512 Гб ({t('home.registrator.prop4')})</div></li>
                    <li><div>{t('home.registrator.prop5')}</div><div></div><div>H.264</div></li>
                    <li><div>{t('home.registrator.prop6')}</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop7')}</div><div></div><div>12/24 V DC</div></li>
                    <li><div>{t('home.registrator.prop8')}</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop9')}</div><div></div><div>{t('home.registrator.prop10')}</div></li>
                    <li><div>{t('home.registrator.prop11')}</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop12')}</div><div></div><div>{t('home.registrator.prop13')}</div></li>
                    <li><div>{t('home.registrator.prop14')}</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop15')}</div><div></div><div>{t('home.registrator.prop2')}</div></li>
                    <li><div>{t('home.registrator.prop16')}</div><div></div><div>142x153x32mm</div></li>
                    <li><div>{t('home.registrator.prop17')}</div><div></div><div>-40… +70°C</div></li>
                </ul>
            </div>
        </div>
    </div>

    )
};

export default Registrator