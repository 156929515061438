import React from 'react'
import BenefitItem from '../components/BenefitItem'

import { useTranslation, setLanguage } from 'react-multi-lang';

const Benefits = (props) => {
    const t = useTranslation()

    return (
    <section className="container">
        <h2 className="text-bolder">{t('home.benefits.benefits1')}</h2>
        <div className="panels panel-5">
            <div class="panel bg-gray text-size-mini benefit-item"><img class="benefit-img" src="/img/benefits/icon-komplekt.png" alt="Преимущества комплектов NSCAR" /><p class="text-size-mini text-center">{t('home.benefits.benefits2')}</p></div>
            <div class="panel bg-gray text-size-mini benefit-item"><img class="benefit-img" src="/img/benefits/icon-reg.png" alt="Преимущества комплектов NSCAR" /><p class="text-size-mini text-center">{t('home.benefits.benefits3')}</p></div>
            <div class="panel bg-gray text-size-mini benefit-item"><img class="benefit-img" src="/img/benefits/icon-size.png" alt="Преимущества комплектов NSCAR" /><p class="text-size-mini text-center">{t('home.benefits.benefits4')}</p></div>
            <div class="panel bg-gray text-size-mini benefit-item"><img class="benefit-img" src="/img/benefits/icon-tool.png" alt="Преимущества комплектов NSCAR" /><p class="text-size-mini text-center">{t('home.benefits.benefits4')}</p></div>
            <div class="panel bg-gray text-size-mini benefit-item"><img class="benefit-img" src="/img/benefits/icon-support.png" alt="Преимущества комплектов NSCAR" /><p class="text-size-mini text-center">{t('home.benefits.benefits6')}</p></div>
        </div>
    </section>
    )
};

export default Benefits