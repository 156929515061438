import React from 'react'
import './ProductItem.css'

function listItems(items) {
    var arrayItems = [];
    items.forEach((element) => {
        arrayItems.push(element);
    });

    return arrayItems.map((item) => <li>{item}</li>);
}
function listItemsEllipsis(items) {
    var arrayItems = [];
    items.forEach((element) => {
        arrayItems.push(element);
    });

    return arrayItems.map((item) => <li><div>{item[0]}</div><div></div><div>{item[1]} руб.</div></li>);
}

const ProductItem = (props) => (
    <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item">
        <div className="card">
            <h3 className="">{props.title}</h3>
            <ul>
                {listItems(props.featuresList)}
            </ul>
            <p className={props.dopClass + ' price'}>{props.price} руб.</p>
            <ul className="dotted-list no-wrap dops">{listItemsEllipsis(props.addonsList)}</ul>
            <label htmlFor="modal" className="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger={props.title + ' - ' + props.subtitle + ' - ' + props.price} aria-controls="wdb9l">Отправить заявку</label>
        </div>
    </div>
);

export default ProductItem