import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Socials from '../components/Socials';
import Breadcrumbs from '../components/Breadcrumbs';
import '../components/Support.css';

import kirill from '../img/workers/kirill.webp';
import yurij from '../img/workers/yurij.webp';

import f1 from '../files/docs/svidetelstvo-na-tovarnyy-znak-nscar.pdf';
import f2 from '../files/docs/sertifikat-klever-dlya-sajta.pdf';
import f3 from '../files/docs/deklaratsiya-o-sootvetstvii-nscar.pdf';
import f4 from '../files/docs/deklaratsiya-o-sootvetstvii-nscar-kamery.pdf';
import f5 from '../files/docs/decl-cam.pdf';
import prg1 from '../files/dist/mdvrplayersetup-1.zip';
import prg2 from '../files/dist/Программа настройки камер ADAS-DSM.apk';
import tp1 from '../files/docs/teh.pasport-nscar.docx';
import tp2 from '../files/docs/teh.pasport-nscar-dvr468.pdf';
import tp3 from '../files/docs/teh.pasport-f864.pdf';
import tp4 from '../files/docs/teh.pasport-nscar.pdf';
import instr1 from '../files/docs/rukovodstvo-po-ekspluatatsii-mdvr-pleer-dlya-864-registratorov.zip';
import instr2 from '../files/docs/instruktsiya-k-registratoram-nscar2023.pdf';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Support = (props) => {

    const t = useTranslation()
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#files') {
            setTimeout(() => {
                const element = document.getElementById('files');
                if (location.hash === '#files' && element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, [location]);

    return (
        <section  className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs3')}</li></ul>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <div className="container text">
                <h1 className="text-center mb-5">{t('home.support.support1')}</h1>
                <p className="text-center">{t('home.support.support2')}</p>
                <button className="btn btn-lg btn-primary-outline center-block mt-30 mb-30" data-modal-trigger="" data-modal-target="call" aria-controls="ecull">{t('home.support.support3')}</button>

                <div className="workers row">
                    <div className="worker">
                        <img height="150" width="150" src={kirill} alt="Сотрудник Кирилл"/>
                        <span className="mt-30 mb-20">Кирилл</span>
                        <a className="text-bolder text-size-medium mb-20" href="tel:87172760582">8(717) 276-05-82</a>
                        <Socials whatsapp="79933992360" viber="chat?number=79933992360" telegram="+79933992360"/>
                        <a className="mt-20 text-center" href="mailto:support@nscar.kz">support@nscar.kz</a>
                    </div>
                    <div className="worker">
                        <img height="150" width="150" src={yurij} alt="Сотрудник Юрий"/>
                        <span className="mt-30">Юрий</span>
                        <a className="text-bolder text-size-medium mb-20" href="tel:87172760582">8(717) 276-05-82</a>
                        <Socials whatsapp="79933992360" viber="chat?number=79933992360" telegram="+79933992360"/>
                        <a className="mt-20 text-center" href="mailto:support@nscar.kz">support@nscar.kz</a>
                    </div>
                </div>
                <div>
                    <h2 className="mb-50 mt-150">{t('home.support.support4')}</h2>
                    <h3 className="text-bolder text-size-normal">{t('home.support.support5')}</h3>
                    <ul className="downloads">
                        <li>
                            <a href={f1} download>
                                <span>{t('home.support.support6')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={f2} download>
                                <span>{t('home.support.support7')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={f3} download>
                                <span>{t('home.support.support8')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={f4} download>
                                <span>{t('home.support.support9')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={f5} download>
                                <span>{t('home.support.support10')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                    </ul>
                    <h3 className="text-bolder text-size-normal">{t('home.support.support11')}</h3>
                    <ul className="downloads">
                        <li>
                            <a href={prg1} download>
                                <span>{t('home.support.support12')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={prg2} download>
                                <span>{t('home.support.support13')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                    </ul>
                    <h3 className="text-bolder text-size-normal">{t('home.support.support14')}</h3>
                    <ul className="downloads">
                        <li>
                            <a href={tp1} download>
                                <span>{t('home.support.support15')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={tp2} download>
                                <span>{t('home.support.support16')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <div id="files"></div>
                        <li>
                            <a href={tp3} download>
                                <span>{t('home.support.support17')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={tp4} download>
                                <span>{t('home.support.support18')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                    </ul>
                    <h3 className="text-bolder text-size-normal">{t('home.support.support19')}</h3>
                    <ul className="downloads">
                        <li>
                            <a href={instr1} download>
                                <span>{t('home.support.support20')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                        <li>
                            <a href={instr2} download>
                                <span>{t('home.support.support21')}</span>
                                <span>Скачать&nbsp;🡓</span>
                            </a>
                        </li>
                    </ul>

                    <h2 className="text-center">{t('home.support.support22')}</h2>
                    <div className="row mt-5">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/2w_0JSqya4k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/vC3vvOFP_Ew" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/GNjB5jzPinY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/MwpkEdl-_vA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/SSI1wQ-Yyf4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/jlyEEeCND_w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 col-md-offset-3 col-sm-offset-0 col-xs-offset-0">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/Jd1XmCkFjLA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>

                    <button className="btn btn-lg btn-primary-outline center-block mt-30 mb-30" data-modal-trigger="" data-modal-target="call" aria-controls="ecull">{t('home.modal.title_tp')}</button>

                    <section>
                        <h2>{t('home.support.faq')}</h2>
                        <details>
                            <summary>1. {t('home.support.faq1')}</summary>
                            <p>{t('home.support.faq2')}</p>
                        </details>
                        <details>
                            <summary>2. {t('home.support.faq4')}</summary>
                            <p>{t('home.support.faq4')}</p>
                        </details>
                        <details>
                            <summary>3. {t('home.support.faq5')}</summary>
                            <p>{t('home.support.faq6')}</p>
                        </details>
                        <details>
                            <summary>4. {t('home.support.faq7')}</summary>
                            <p>{t('home.support.faq8')}</p>
                            <p>{t('home.support.faq9')}</p>
                        </details>
                        <details>
                            <summary>5. {t('home.support.faq10')}</summary>
                            <p>{t('home.support.faq11')}</p>
                            <p>{t('home.support.faq12')}</p>
                            <p>{t('home.support.faq13')}</p>
                            <p>{t('home.support.faq14')}</p>
                        </details>
                        <details>
                            <summary>6. {t('home.support.faq15')}</summary>
                            <p>{t('home.support.faq16')}</p>
                        </details>
                        <details>
                            <summary>7. {t('home.support.faq17')}</summary>
                            <p>{t('home.support.faq18')}</p>
                        </details>
                        <details>
                            <summary>8. {t('home.support.faq19')}</summary>
                            <p>{t('home.support.faq20')}</p>
                        </details>
                        <details>
                            <summary>9. {t('home.support.faq21')}</summary>
                            <p>{t('home.support.faq22')}</p>
                        </details>

                    </section>
                </div>
            </div>
        </section>
    )
}

export default Support