import React from 'react'
import './Hero.css';
// import Marquiz from '../components/Marquiz';

import mainImage from '../img/hero_image.png';
import backgroundImage from '../img/background.png';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Hero = () => {
    const t = useTranslation();

    return (
        <div className="container">

                <div className="row">
                    <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12">
                        <h1 className="hero__title text-bolder">{t('home.hero.hero__title')}</h1>
                        <div className="hero__usage-description badge badge-primary">
                            {t('home.hero.hero__usage-description')}
                        </div>
                        <div className="hero__description">
                            <p className="text-bolder text-size-medium mb-20">{t('home.price.price1')} {t('home.price.currency')}</p>
                            {t('home.hero.hero__description')}
                        </div>
                        <div className="hero__btns">
                            {/*<Marquiz/>*/}
                            <button className="btn btn-lg btn-primary" data-modal-trigger="" data-modal-target="call" aria-controls="uhezl">{t('home.hero.hero__btns1')}</button>
                            <button className="badge badge-primary badge-border light" data-modal-trigger="" data-modal-target="call" aria-controls="uhezl">{t('home.hero.hero__btns2_1')}<br></br>{t('home.hero.hero__btns2_2')}</button>
                            {/*<label className="btn btn-warning" >Отправить заявку</label>*/}
                            {/*<label className="btn btn-warning" data-modal-trigger="" data-modal-target="call" aria-controls="send">Заказать звонок</label>*/}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12 visible-md hidden-sm hidden-xs last-md last-lg hero__visual-content">
                        <img className="image"alt="4-х канальный регистратор, 4 камеры, провода для подключения (5м, 10м)" src={mainImage}></img>
                        <img className="background" alt="4-х канальный регистратор, 4 камеры, провода для подключения (5м, 10м)" src={backgroundImage}></img>
                    </div>
                </div>
        </div>
    )
};

export default Hero