import React from 'react';
import ReactDOM from 'react-dom';

import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";

import { YMInitializer } from 'react-yandex-metrika';
// import TagManager from 'react-gtm-module-nonce-patch';

import { setTranslations, setDefaultLanguage } from 'react-multi-lang';
import ru from './translations/ru.json';
import kz from './translations/kz.json';

setTranslations({ru, kz});
setDefaultLanguage('ru');

const currentLang = localStorage.getItem("lang");
if (currentLang) {
    setDefaultLanguage(currentLang);
} else {
    let language = window.navigator.language;
    let lang = language.substring(3, 2);
    if (lang === 'kz') {
        setDefaultLanguage('kz');
    } else {
        setDefaultLanguage('ru');
    }

}

// const tagManagerArgs = {
//     gtmId: 'GTM-NTXSQHBK'
// };
//
// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <YMInitializer accounts={[97076674]} options={{webvisor: true}}/>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
