import React from 'react';
import Form from '../components/Form';
import { getLanguage, useTranslation, setLanguage } from 'react-multi-lang';

const Modal = (props) => {
    const t = useTranslation();

    return (
        <div>
            <div className="modal" id="axjnf" data-modal-target="">
                <div className="modal__overlay" data-modal-close="" tabIndex="-1"></div>
                <div className="modal__wrapper">
                    <div className="modal__header">
                        <div className="modal__title" id="modal_title">{t('home.hero.hero__btns1')}</div>
                        <button className="modal__close" data-modal-close="" aria-label="Close Modal"></button>
                    </div>
                    <div className="modal__content">
                        <iframe
                            src={(getLanguage() === 'ru') ? 'https://b24-d7co8x.bitrix24.site/crm_form_axjnf' : 'https://b24-d7co8x.bitrix24.site/crm_form_kko5r'}
                            style={{
                                width: '100%',
                                height: '610px',
                                border: 'none'
                            }}/>
                        {/* <Form url={props.url}/> */}
                    </div>
                </div>
            </div>
            <div className="modal" id="uhezl" data-modal-target="">
                <div className="modal__overlay" data-modal-close="" tabIndex="-1"></div>
                <div className="modal__wrapper">
                    <div className="modal__header">
                        <div className="modal__title" id="modal_title">{t('home.hero.hero__btns1')}</div>
                        <button className="modal__close" data-modal-close="" aria-label="Close Modal"></button>
                    </div>
                    <div className="modal__content">
                        <iframe
                            src={(getLanguage() === 'ru') ? 'https://b24-d7co8x.bitrix24.site/crm_form_uhezl' : 'https://b24-d7co8x.bitrix24.site/crm_form_r9sre/'}
                            style={{
                                width: '100%',
                                height: '610px',
                                border: 'none'
                            }}/>
                    </div>
                </div>
            </div>
            <div className="modal" id="wdb9l" data-modal-target="">
                <div className="modal__overlay" data-modal-close="" tabIndex="-1"></div>
                <div className="modal__wrapper">
                    <div className="modal__header">
                        <div className="modal__title" id="modal_title">{t('home.hero.hero__btns1')}</div>
                        <button className="modal__close" data-modal-close="" aria-label="Close Modal"></button>
                    </div>
                    <div className="modal__content">
                        <iframe
                            src={(getLanguage() === 'ru') ? 'https://b24-d7co8x.bitrix24.site/crm_form_wdb9l' : 'https://b24-d7co8x.bitrix24.site/crm_form_yrv6h/'}
                            style={{
                                width: '100%',
                                height: '610px',
                                border: 'none'
                            }}/>
                        {/* <Form url={props.url}/> */}
                    </div>
                </div>
            </div>
            <div className="modal" id="ecull" data-modal-target="">
                <div className="modal__overlay" data-modal-close="" tabIndex="-1"></div>
                <div className="modal__wrapper">
                    <div className="modal__header">
                        <div className="modal__title" id="modal_title">{t('home.modal.title_tp')}</div>
                        <button className="modal__close" data-modal-close="" aria-label="Close Modal"></button>
                    </div>
                    <div className="modal__content">
                        <iframe
                            src={(getLanguage() === 'ru') ? 'https://b24-d7co8x.bitrix24.site/crm_form_ecull/' : 'https://b24-d7co8x.bitrix24.site/crm_form_2zrcw/'}
                            style={{
                                width: '100%',
                                height: '610px',
                                border: 'none'
                            }}/>
                        {/* <Form url={props.url}/> */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Modal