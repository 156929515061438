import React from 'react';
import './Videos.css'

import { useTranslation, setLanguage } from 'react-multi-lang';

const Videos = (props) => {
    const t = useTranslation()

    return (
    <section className="container">
        <h2 className="text-center text-bolder">{t('home.videos.videos1')}</h2>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Jd1XmCkFjLA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/vC3vvOFP_Ew" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/QaZhTYsjQZI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/TDIKfVYhf3o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </section>
    )
};

export default Videos