import React from 'react';
import './Videos.css';

import { useTranslation, setLanguage } from 'react-multi-lang';

var videoShow = function () {
    let YouTubeContainers = document.querySelectorAll(".embed-youtube");
    for (let i = 0; i < YouTubeContainers.length; i++) {
        let container = YouTubeContainers[i];
        let imageSource = "https://img.youtube.com/vi/"+ container.dataset.videoId +"/sddefault.jpg";
        let image = new Image();
        image.src = imageSource;
        image.addEventListener("load", function() {
            container.appendChild(image);
        });
        container.addEventListener("click", function() {
            let iframe = document.createElement( "iframe" );
            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
            // Important: add the autoplay GET parameter, otherwise the user would need to click over the YouTube video again to play it
            iframe.setAttribute("src", "https://www.youtube.com/embed/"+ this.dataset.videoId +"?rel=0&showinfo=0&autoplay=1");
            // Clear Thumbnail and load the YouTube iframe
            this.innerHTML = "";
            this.appendChild( iframe );
        });
    }
};

const Testimonial = (props) => {
    const t = useTranslation()

    return (
    <section className="container p-0">
        <h2 className="text-center text-bolder">{t('home.testimonial.testimonial1')}</h2>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/0ePgxeZjcMg?si=ylPOpYCQI5-c-6Wg&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<div className="embed-youtube" data-video-id='0ePgxeZjcMg'>*/}
                    {/*<div className="embed-youtube-play"></div>*/}
                {/*</div>*/}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/qoX4j9ufhuY?si=CO_UWedhutS8Zt8U&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<div className="embed-youtube" data-video-id='qoX4j9ufhuY'>*/}
                    {/*<div className="embed-youtube-play"></div>*/}
                {/*</div>*/}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/POYOETKq8ak?si=FDpory9WQSFTn9ed&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<div className="embed-youtube" data-video-id='POYOETKq8ak'>*/}
                    {/*<div className="embed-youtube-play"></div>*/}
                {/*</div>*/}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yytxZlQeJm0?si=nUwoBw6XSOrpzBFv&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<div className="embed-youtube" data-video-id='yytxZlQeJm0'>*/}
                    {/*<div className="embed-youtube-play"></div>*/}
                {/*</div>*/}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/jUmWQMrows0?si=mskm--1yyIFtU6UA&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<div className="embed-youtube" data-video-id='jUmWQMrows0'>*/}
                    {/*<div className="embed-youtube-play"></div>*/}
                {/*</div>*/}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/aFkEyXmaCUM?si=OPFKssQ5_ocISxT1&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<div className="embed-youtube" data-video-id='aFkEyXmaCUM'>*/}
                    {/*<div className="embed-youtube-play"></div>*/}
                {/*</div>*/}
            </div>
        </div>
    </section>
    )
};

export default Testimonial