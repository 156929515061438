import React from 'react'
import { Helmet } from 'react-helmet';

import Breadcrumbs from '../components/Breadcrumbs';

import ofis from '../img/ofis.webp';

import { useTranslation, setLanguage } from 'react-multi-lang';

const About = (props) => {
    const t = useTranslation()

    return (
    <section className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs6')}</li></ul>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
        <div className="container text">
            <h1 className="text-center">{t('home.about.about1')}</h1>
            <div className="row">
                <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12">
                    <img className="border-20" src={ofis} alt="Офис NSCAR"/>
                </div>
                <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12" style={{margin:'auto'}}>
                    <p className="m-0 text-left">{t('home.about.about2')}</p>
                    <p className="m-0 text-left">{t('home.about.about3')}</p>
                    <p className="m-0 text-left">{t('home.about.about4')}</p>
                </div>
            </div>
            <pre>

            </pre>
            <p className="m-0 text-left">{t('home.about.about5')}</p>
            <p className="m-0 text-left">{t('home.about.about6')}</p>
            <pre>
            </pre>
            <p className="m-0 text-left">{t('home.about.about7')}</p>
            <pre>
            </pre>
            <p className="m-0 text-left">{t('home.about.about8')}</p>
            <pre>
            </pre>
            <p className="m-0 text-left">{t('home.about.about9')}</p>
            <p className="m-0 text-left">{t('home.about.about10')}</p>
            <pre>
            </pre>
            <p className="m-0 text-left">{t('home.about.about11')}</p>
            <p className="m-0 text-left">• {t('home.about.about12')}</p>
            <p className="m-0 text-left">• {t('home.about.about13')}</p>
            <p className="m-0 text-left">• {t('home.about.about14')}</p>
            <p className="m-0 text-left">• {t('home.about.about15')}</p>
        </div>

    </section>
    )
};

export default About