import React from 'react'

const open = () => {
    let burger = document.getElementById('hamburger'), humMenu = document.getElementById('hamburger-menu');
    burger.classList.toggle('is-active');
    humMenu.classList.toggle('active');
    let header__logo = document.getElementById('header__logo'), header__dashboard_btn = document.getElementById('header__dashboard_btn');
    header__dashboard_btn.classList.toggle('active');
};

const Humburger = () => (
    <div id="hamburger" className="hamburger hamburger--spin" onClick={open}>
        <span className="hamburger-box">
            <span className="hamburger-inner"></span>
        </span>
    </div>
);

export default Humburger


