import React from 'react'
import {Link} from 'react-router-dom'

import Humburger from '../components/Humburger';
import Modal from '../components/Modal';
import './Headers.css';

import logo_white from '../img/logo.png';
import iconPhoneOutWhite from '../img/icon-phone-out-white.svg';
import iconWhatsapp from '../img/icon-whatsapp-colored.svg';
import iconViber from '../img/icon-viber-colored.svg';
import iconEnvelopeWhite from '../img/icon-envelope-white.svg';
import iconClockWhite from '../img/icon-clock-white.svg';

import ru from '../img/russia.png';
import kz from '../img/kazakhstan.png';

import '../components/Modal.css';

import { useTranslation, setLanguage } from 'react-multi-lang';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import ym from 'react-yandex-metrika';


var modal = function () {
    if (!Element.prototype.closest) {
        if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
        }
        Element.prototype.closest = function (s) {
            var el = this;
            var ancestor = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (ancestor.matches(s)) return ancestor;
                ancestor = ancestor.parentElement;
            } while (ancestor !== null);
            return null;
        };
    }

    function trapFocus(element) {
        var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        element.addEventListener('keydown', function(e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
            if (!isTabPressed) {
                return;
            }
            if ( e.shiftKey ) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    }

    var settings = {
        speedOpen: 50,
        speedClose: 250,
        activeClass: 'is-active',
        visibleClass: 'is-visible',
        selectorTarget: '[data-modal-target]',
        selectorTrigger: '[data-modal-trigger]',
        selectorClose: '[data-modal-close]',
    };

    var toggleccessibility = function (event) {
        if (event.getAttribute('aria-expanded') === 'true') {
            event.setAttribute('aria-expanded', false);
        } else {
            event.setAttribute('aria-expanded', true);
        }
    };

    var openModal = function (trigger) {
        var target = document.getElementById(trigger.getAttribute('aria-controls')), modal_target = trigger.dataset.modalTarget;
        target.classList.add(settings.activeClass);
        document.documentElement.style.overflow = 'hidden';

        /**
        if (target.id === 'send') {
            document.getElementById('model').setAttribute('value', trigger.dataset.modalTrigger);
        }
        if (modal_target === 'call') {
            document.getElementById('modal_title').textContent="Отправить заявку на обратный звонок";
        } else {
            document.getElementById('modal_title').textContent="Отправить заявку";
        }
        **/

        toggleccessibility(trigger);
        setTimeout(function () {
            target.classList.add(settings.visibleClass);
            trapFocus(target);
        }, settings.speedOpen);
    };

    var closeModal = function (event) {
        var closestParent = event.closest(settings.selectorTarget),
            childrenTrigger = document.querySelector('[aria-controls="' + closestParent.id + '"');
        closestParent.classList.remove(settings.visibleClass);
        document.documentElement.style.overflow = '';
        toggleccessibility(childrenTrigger);
        setTimeout(function () {
            closestParent.classList.remove(settings.activeClass);
        }, settings.speedClose);
    };

    var clickHandler = function (event) {
        var toggle = event.target,
            open = toggle.closest(settings.selectorTrigger),
            close = toggle.closest(settings.selectorClose);
        if (open) {
            openModal(open);
        }
        if (close) {
            closeModal(close);
        }
        if (open || close) {
            event.preventDefault();
        }
    };

    var keydownHandler = function (event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
            var modals = document.querySelectorAll(settings.selectorTarget), i;
            for (i = 0; i < modals.length; ++i) {
                if (modals[i].classList.contains(settings.activeClass)) {
                    closeModal(modals[i]);
                }
            }
        }
    };
    document.addEventListener('click', clickHandler, false);
    document.addEventListener('keydown', keydownHandler, false);
};
modal();

// TOP NAVIGATION SCRIPT
var fixedHeader = function () {
    // высота "шапки", px
    var h_hght = 134;//(document.getElementById('header')) ? document.getElementById('header').offsetHeight : 0; // высота блока с меню, px
    var h_nav = 70;
    var top;
    window.addEventListener('scroll', function(e) {
        // отступ сверху
        top = window.scrollY;

        if (top >= h_hght) {
            document.getElementById('top_nav').classList.add('fixed');
        } else {
            document.getElementById('top_nav').classList.remove('fixed');
        }
    });
};

fixedHeader();

var switchLang = function (lang) {
    // () => setLanguage(lang)
};

const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    localStorage.setItem("lang", selectedLanguage);
};




function Header() {
    const t = useTranslation();

    // const handleYandexMetrika = function(event) {
    //     console.log(event);
    //     //ym(97076674, 'reachGoal', 'event');
    
    //     ym('reachGoal', event, {awesomeParameter: 97076674});
    // };

    return <header id="header" className="header">
        <aside>
            <nav className="menu">
                <div className="container">
                    <ul className="menu__list">
                        <li>
                            <li className="menu__item"><Link to="/about" className="menu__item-link">{t('home.header.О компании')}</Link></li>
                            <li className="menu__item"><Link to="/delivery" className="menu__item-link">{t('home.header.Доставка/Оплата')}</Link></li>
                            <li className="menu__item"><Link to="/opt" className="menu__item-link">{t('home.header.Опт/Прайс')}</Link></li>
                            <li className="menu__item"><Link to="/support" className="menu__item-link">{t('home.header.Техподдержка')}</Link></li>
                            <li className="menu__item"><Link to="/contacts" className="menu__item-link">{t('home.header.Контакты')}</Link></li>
                        </li>
                        <li>
                            <li className="menu__item hidden-sm visible-lg">
                                <span className="header__btn-link">
                                    {/*<img src={iconClockWhite} className="header__dashboard-pic" alt="Время работы"/>*/}
                                    10:00-20:00&nbsp;({t('home.header.ПН-ПТ')})<br/>
                                    12:00-17:00&nbsp;({t('home.header.СБ-ВС')})
                                </span>
                            </li>
                            <li className="menu__item menu__item-column">
                                <a href="tel:87172760582" className="header__btn-link text-bolder">
                                    {/*<img className="header__dashboard-pic" src={iconPhoneOutWhite} alt="Позвонить"/>*/}
                                    8(717) 276-05-82
                                </a>
                                <a href="mailto:info@nscar.kz" className="header__btn-link">
                                    {/*<img src={iconEnvelopeWhite} className="header__dashboard-pic" alt="Написать"/>*/}
                                    info@nscar.kz
                                </a>
                            </li>
                            <li>
                                <button onClick={() => handleLanguageChange('kz')}><img style={{ width: "20px" }} src={kz}/></button>
                                <button onClick={() => handleLanguageChange('ru')}><img style={{ width: "20px" }} src={ru}/></button>
                            </li>
                        </li>


                    </ul>

                </div>
            </nav>
        </aside>
        <div className="container container--header">
            <Link to="/" className="header__logo logo-text" id="header__logo">
                <img height="50" width="auto" src={logo_white} alt="nscar.kz" />
            </Link>
            <div className="header__content">
                <div style={{margin: 'auto'}} className='mobile-visible'>
                    <a className="text-bolder" href="tel:87172760582">8(717) 276-05-82</a>
                </div>
                <div className="header__dashboard-btn text-white" id="header__dashboard_btn">
                {/* onClick={handleYandexMetrika('shapka_getconsultation_click')} */}
                    <div className="btn btn-primary-outline pointer" data-modal-trigger="" data-modal-target="call" aria-controls="axjnf">
                        {t('home.header.Получить консультацию')}
                    </div>
                </div>
                <Humburger/>
            </div>
        </div>


        <Modal/>

    </header>;
}

export default Header;