import React from 'react'
import './Socials.css';

import wa from '../img/icon-whatsapp-colored.svg';
import vb from '../img/icon-viber-colored.svg';
import tg from '../img/icon-telegram-colored.svg';


const Socials = (props) => (
    <div className="socials-btns">
        <div>
            <a target="_blank" rel="nofollow noopener" href={'https://wa.clck.bar/' + props.whatsapp} aria-label="Ссылка">
                <img src={wa} alt="Логотип" />
            </a>
        </div>
        <div>
            <a target="_blank" rel="nofollow noopener" href={'viber://' + props.viber} aria-label="Ссылка">
                <img src={vb} alt="Логотип"  />
            </a>
        </div>
        {props.telegram && <div>
            <a target="_blank" rel="nofollow noopener" href={'https://t.me/' + props.telegram} aria-label="Ссылка">
                <img src={tg} alt="Логотип" />
            </a>
        </div>}
    </div>
);

export default Socials