import React from 'react'
import ProductItem from '../components/ProductItem'
// import Addon from '../components/Addon'
// import iconBox from '../img/icon-box-colored.svg';
import komplekt1 from '../img/komplekt/01.png';
import komplekt2 from '../img/komplekt/02.png';
import komplekt3 from '../img/komplekt/03.png';
import komplekt4 from '../img/komplekt/04.png';
import base from '../img/komplekt/base.png';
import ahd from '../img/komplekt/ahd.png';
import fullhd from '../img/komplekt/fullhd.png';
import dop1 from '../img/komplekt/dop/monitor.png';
import dop2 from '../img/komplekt/dop/flash.png';
import dop3 from '../img/komplekt/dop/provoda2.png';
import dop4 from '../img/komplekt/dop/kamera.png';

import { useTranslation } from 'react-multi-lang';

const Products = (props) => {
    const t = useTranslation();

    return (
    <div>
        <div className="bg-gray">
            <div className="container">
                <h2 className="text-bolder text-size-medium mt-70 mb-50">{t('home.products.text_bolder1')}</h2>
                <div className="row">
                    <ul className="panels panels-4">
                        <li className="panel"><img alt="komplekt" src={komplekt1}/>{t('home.products.komplekt1')}</li>
                        <li className="panel"><img alt="komplekt" src={komplekt2}/>{t('home.products.komplekt2')}</li>
                        <li className="panel"><img alt="komplekt" src={komplekt3}/>{t('home.products.komplekt3')}</li>
                        <li className="panel"><img alt="komplekt" src={komplekt4}/>{t('home.products.komplekt4')}</li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <h3 className="text-bolder text-size-medium mt-70 mb-50">{t('home.products.text_bolder2')}</h3>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <div className="addonBlock">
                            <img alt="komplekt" src={dop1}/>
                            <ul className="dotted-list not-dotted-onmobile">
                                <li><div>{t('home.products.monitor')} 7''</div><div></div><div>{t('home.price.price2')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.monitor')} 4.3''</div><div></div><div>{t('home.price.price3')}&nbsp;{t('home.price.currency')}</div></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <div className="addonBlock">
                            <img alt="komplekt" src={dop2}/>
                            <ul className="dotted-list not-dotted-onmobile">
                                <li><div>{t('home.products.karta_pamyati')} SD 64Гб</div><div></div><div>{t('home.price.price5')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.karta_pamyati')} SD 128Гб</div><div></div><div>{t('home.price.price6')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.karta_pamyati')} SD 256Гб</div><div></div><div>{t('home.price.price7')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.karta_pamyati')} SD 512Гб</div><div></div><div>{t('home.price.price8')}&nbsp;{t('home.price.currency')}</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="visible-md hidden-sm hidden-xs"/>
                <div className="row mb-50">
                    <div className="col-md-6 col-xs-12">
                        <div className="addonBlock">
                            <img alt="komplekt" src={dop3}/>
                            <ul className="dotted-list not-dotted-onmobile">
                                <li><div>{t('home.products.dop_kabel')}</div><div></div><div>от {t('home.price.price4')}&nbsp;{t('home.price.currency')}</div></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <div className="addonBlock">
                            <img alt="komplekt" src={dop4}/>
                            <ul className="dotted-list not-dotted-onmobile">
                                <li><div>{t('home.products.karta_mini1')}</div><div></div><div>{t('home.price.price9')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.karta_mini2')}</div><div></div><div>{t('home.price.price10')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.camera')} HD</div><div></div><div>{t('home.price.price11')}&nbsp;{t('home.price.currency')}</div></li>
                                <li><div>{t('home.products.camera')} FullHD</div><div></div><div>{t('home.price.price12')}&nbsp;{t('home.price.currency')}</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="products">
                <h2 className="text-center text-size-big text-bolder mt-70">{t('home.products.text_bolder3')}</h2>

                <h3 className="text-center text-size-medium">{t('home.products.text_bolder4')} (720х576)</h3>
                <div className="row">
                    <img className="col-lg-3 col-lg-offset-0 hidden-md visible-lg col-sm-4 col-sm-offset-4 col-xs-6 col-xs-offset-3 product-image" src={base} alt="Базовый комплект"/>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar1')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator')}</li><li>камеры NSCAR 306 2шт.</li><li>{t('home.products.kompl_kab')} (5м).</li></ul><p class="undefined price">{t('home.price.price13')}&nbsp;{t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price28')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 2 камеры (SD до 512Гб) - undefined - 11500" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar2')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator')}</li><li>камеры NSCAR 306 4шт.</li><li>{t('home.products.kompl_kab')} (5м).</li></ul><p class="undefined price">{t('home.price.price14')}&nbsp;{t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price29')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 4 камеры (SD до 512Гб) - undefined - 13800" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar3')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator')} + квадратор 1шт.</li><li>камеры NSCAR 306 5шт.</li><li>{t('home.products.kompl_kab')} (5м).</li></ul><p class="undefined price">{t('home.price.price15')}&nbsp;{t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price30')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 5 камер (SD до 512Гб) - undefined - 19000" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                </div>

                <h3 className="text-center text-size-medium">{t('home.products.text_bolder5')} (1280*720)</h3>
                <div className="row">
                    <img className="col-lg-3 col-lg-offset-0 hidden-md visible-lg col-sm-4 col-sm-offset-4 col-xs-6 col-xs-offset-3 product-image" src={ahd} alt="AHD комплект"/>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar1')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator')}</li><li>камеры NSCAR A305 1шт., AJ104 1шт.</li><li>{t('home.products.kompl_kab')} (3м).</li></ul><p class="mt-20 price">{t('home.price.price16')} {t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price17')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 2 камеры - undefined - 15780" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">AHD {t('home.products.komplekt_nscar2')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator')}</li><li>камеры NSCAR A305 1шт., AJ104 3шт.</li><li>{t('home.products.kompl_kab')} (3м).</li></ul><p class="mt-20 price">{t('home.price.price18')} {t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price19')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 4 AHD камерs (SD до 512Гб) - undefined - 21340" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">AHD {t('home.products.komplekt_nscar3')} (HDD до 2Тб + SD до 512Гб)</h3><ul><li>{t('home.products.registrator2')}</li><li>камеры NSCAR A305 1шт., AJ104 4шт.</li><li>{t('home.products.kompl_kab')} (3м).</li></ul><p class="mt-20 price">{t('home.price.price20')} {t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price21')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 5 AHD камер (HDD до 2Тб + SD до 512Гб) - undefined - 34420" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                </div>

                <h3 className="text-center text-size-medium">{t('home.products.text_bolder6')} (1920*1080)</h3>
                <div className="row">
                    <img className="col-lg-3 col-lg-offset-0 hidden-md visible-lg col-sm-4 col-sm-offset-4 col-xs-6 col-xs-offset-3 product-image" src={fullhd} alt="FullHD комплект"/>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar1')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator3')}</li><li>камеры NSCAR FD317 2шт.</li><li>{t('home.products.kompl_kab')} (5м, 10м).</li></ul><p class="undefined price">{t('home.price.price22')} {t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price23')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 2 камеры (SD до 512Гб) - undefined - 15065" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar2')} (SD до 512Гб)</h3><ul><li>{t('home.products.registrator3')}</li><li>камеры NSCAR FD317 4шт.</li><li>{t('home.products.kompl_kab')} (5м, 10м).</li></ul><p class="undefined price">{t('home.price.price24')} {t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price25')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 4 камеры (SD до 512Гб) - undefined - 15900" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 product-item"><div class="card"><h3 class="">{t('home.products.komplekt_nscar3')} (HDD до 2Тб + SD до 512Гб)</h3><ul><li>{t('home.products.registrator4')}</li><li>камеры NSCAR FD317 5шт.</li><li>{t('home.products.kompl_kab')} (5м, 10м).</li></ul><p class="undefined price">{t('home.price.price26')} {t('home.price.currency')}</p><ul class="dotted-list no-wrap dops"><li><div>с 4G/GPS/{t('home.products.glonass')}</div><div></div><div>{t('home.price.price27')} {t('home.price.currency')}</div></li></ul><label for="modal" class="btn btn-lg btn-primary-outline no-wrap" data-modal-trigger="Комплект NSCAR на 5 камер (HDD до 2Тб + SD до 512Гб) - undefined - 17250" aria-controls="wdb9l">{t('home.hero.hero__btns1')}</label></div></div>
                </div>
            </div>



        </div>
    </div>
    )
};

export default Products