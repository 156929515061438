import React from 'react'
import { Helmet } from 'react-helmet';

import Socials from '../components/Socials';
import Breadcrumbs from '../components/Breadcrumbs';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Contacts = (props) => {
    const t = useTranslation()

    return (
    <section className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs2')}</li></ul>
        <Helmet>
            <title>{props.title}</title>
        </Helmet>
        <div className="container text">
            <h1 className="text-center">{t('home.contacts.contacts1')}</h1>
            <h2><div className="hr-text"><div className="w-separator-h text-dark text-bolder text-size-normal">{t('home.contacts.contacts2')}</div></div></h2>
            <p className="text-center text-bolder text-size-medium mb-50">Казахстан, г. Астана, ул. Е 900, д. 2</p>
            <h2><div className="hr-text"><div className="w-separator-h text-dark text-bolder text-size-normal">{t('home.contacts.contacts4')}</div></div></h2>
            <div className="center-block mb-50">
                <a className="text-bolder text-size-medium" href="tel:87172760582">8(717) 276-05-82</a><br></br>
                <a className="text-bolder text-size-medium" href="mailto:info@nscar.kz">info@nscar.kz</a><br></br>
                <Socials whatsapp="79195202166" viber="pa?chatURI=nscar" telegram="+79195202166" />
            </div>
            <h2><div className="hr-text"><div className="w-separator-h text-dark text-bolder text-size-normal">{t('home.contacts.contacts4')}</div></div></h2>
            <div className="center-block mb-50">
                <a className="text-bolder text-size-medium" href="tel:87172760582">8(717) 276-05-82</a><br></br>
                <a className="text-bolder text-size-medium" href="mailto:support@nscar.kz">support@nscar.kz</a><br></br>
                <Socials whatsapp="79933992360" viber="chat?number=+79933992360" telegram="+79933992360" />
            </div>
        </div>



    </section>
    )
};

export default Contacts